@font-face {
    font-family: MontserratBold;
    src: url(/src/assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
    font-family: MontserratRegular;
    src: url(/src/assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: MontserratMedium;
    src: url(/src/assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: MontserratThin;
    src: url(/src/assets/fonts/Montserrat-Thin.ttf);
}

:root {
    --dark-bgcolor: #002F67;
    --lightBLue-color: #6EC0FF;
    --lightBLue-disavledcolor: #749CBB;
    --dark-fontcolor: #353C6F;
    --success-color: #02BD54;
    --failur-color: #F23838;
    --failur-textcolor: #ee6262;
    --white-color: #fff;
    --black-color: #000;
    --gray-color: #CCCDD9;
    --mainContainer-margin: 150px;
}

@media screen and (max-width: 1100px) {
    :root {
        --mainContainer-margin: 100px;
    }
}

@media screen and (max-width: 1000px) {
    :root {
        --mainContainer-margin: 0px;
    }
}

.primary-bg {
    background-color: var(--dark-bgcolor);
}

.mainCont {
    min-height: 100vh;
    height: 100vh;
}

.welcomeImageContainer {
    width: fit-content;
}

.personsCardContainer {
    cursor: pointer;
}

.welcomeImage {
    object-fit: cover;
    object-position: top !important;
}

.welcomeBanner {
    font: normal normal bold 80px/87px MontserratBold;
    letter-spacing: 4px;
    color: var(--lightBLue-color);
}

.welcomeText {
    font: normal normal bold 32px/49px MontserratBold;
    letter-spacing: 1.6px;
    color: var(--dark-fontcolor);
}

.welcomeTextContainer {
    background-color: var(--white-color);
    border-radius: 9px;
    position: relative;
    margin: 50px 130px 0;
}

.welcomeTextContainer::after {
    width: 90%
}

.game1IntroLeft {
    background-color: var(--lightBLue-color);
}

.game2IntroRight {
    background-color: var(--dark-bgcolor);
    opacity: 0.35;
}

.game1description {
    width: 75%;
    font: normal normal bold 41px/55px MontserratBold;
    letter-spacing: -0.98px;
}

.game2description {
    width: 75%;
    font: normal normal bold 41px/55px MontserratBold;
    letter-spacing: -0.98px;
}

.finalPG1 {
    font: normal normal 500 20px/24px MontserratMedium;
    letter-spacing: -0.48px;
    line-height: 24px;
    margin: 0 80px;
    opacity: 0.61;
}

.game1Specs {
    background-color: #26284407;
    bottom: 0;
}

.numberSpecs {
    font: normal normal bold 55px/55px MontserratBold;
    letter-spacing: -1.32px;
}

.textSpecs {
    font: normal normal 300 32px/55px MontserratThin;
    letter-spacing: -0.77px;
}

.imgContainer {
    background-color: var(--dark-bgcolor);
    height: 100vh;
}

.imgContainer img {
    object-fit: cover;
    object-position: center;
    height: 100%;
}

.hiddenControlDiv {
    bottom: 200px;
    right: 50px;
}

.group-name-container,
.group-profiles-container {
    border-radius: 9px;
    background-color: var(--white-color);
    height: 100px;
    position: relative;
}

.group-name-container {
    font: normal normal bold 76px/87px MontserratBold;
    line-height: 87px;
    letter-spacing: 3.8px;
    color: var(--dark-fontcolor);
    /* min-width: 160px; */
}

.med-group-name-container {
    font: normal normal bold 32px/40px MontserratBold;
    line-height: 50px;
    letter-spacing: 3.2px;
    color: var(--dark-fontcolor);
    min-width: 120px;
}

.med-group-name-container,
.med-group-profiles-container {
    border-radius: 9px;
    background-color: var(--white-color);
    height: 70px;
    position: relative;
}

.med-group-name-container::after {
    width: 80%;
}

.med-group-profiles-container::after {
    width: 95%;
}

.small-group-name-container {
    font: normal normal bold 32px/40px MontserratBold;
    line-height: 50px;
    letter-spacing: 3.2px;
    color: var(--dark-fontcolor);
    min-width: 120px;
}

.small-group-name-container,
.small-group-profiles-container {
    border-radius: 9px;
    background-color: var(--white-color);
    height: 50px;
    position: relative;
}

.small-group-name-container::after {
    width: 80%;
}

.small-group-profiles-container::after {
    width: 95%;
}

.avatar {
    width: 80px;
    height: 80px !important;
    object-position: top !important;
    aspect-ratio: 1;
    background-color: var(--lightBLue-color);
    border-radius: 50%;
    box-shadow: 0 0 0 5px var(--white-color);
    margin-left: -10px;
}

.avatar:first-child {
    margin-left: 0px;
}

.bottomShadow:after {
    content: '';
    position: absolute;
    height: 10px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 9px 9px;
}

.bottomLightBlueShadow::after {
    background-color: var(--lightBLue-color);
}

.bottomGreenShadow::after {
    background-color: var(--success-color);
    opacity: 0.8;
}

.bottomRedShadow::after {
    background-color: var(--failur-color);
    opacity: 0.8;
}

.group-name-container::after,
.group-profiles-container::after {
    width: 80%;
}

.group-profiles-container::after {
    width: 95%;
}

.mainContainer {
    margin: 0 var(--mainContainer-margin);
}

.groupMainContainer {
    margin: 0 50px;
}

.playerMainContainer {
    margin: 0 100px;
}

.headerTitleLeft {
    color: var(--lightBLue-color);
    font: normal normal bold 41px/87px MontserratBold;
    letter-spacing: 2.05px;
}

.headerTitleRight {
    color: var(--white-color);
    font: normal normal 300 41px/87px MontserratRegular;
    letter-spacing: 2.05px;
}

.personsCard {
    border-radius: 9px;
    height: 310px;
    max-height: 320px;
    position: relative;
}

.personsCard::after {
    width: 80%;
}

.personsCard h2 {
    border-radius: 9px;
    font: normal normal bold 25px/30px MontserratBold;
    color: var(--dark-fontcolor);
    letter-spacing: 1.4px;
    min-height: 62px;
}

.personsCard h3 {
    border-radius: 9px;
    font: normal normal bold 20px/24px MontserratBold;
    color: var(--lightBLue-disavledcolor);
    letter-spacing: 1.4px;
}

.playerImage {
    max-width: 140px;
    max-height: 140px;
    object-position: top !important;
    border: 4px solid var(--lightBLue-color);
    border-radius: 50%;
}

.questionNumberTitle {
    font: normal normal bold 100px/87px MontserratBold;
    letter-spacing: 5.2px;
    color: var(--lightBLue-color);
    margin-top: 150px;
    line-height: 100px;
}

.questionTimeTxt {
    font: normal normal 300 50px/49px MontserratRegular;
    letter-spacing: 2.5px;
    color: var(--dark-fontcolor);
}

.timeBox {
    position: relative;
    background-color: var(--white-color);
    border-radius: 9px;
    margin: 20px 100px;
}

.timeBox::after {
    width: 95%;
}

.numbersRow {
    font: normal normal bold 32px/48px MontserratBold;
    color: var(--lightBLue-disavledcolor);
}

.numbersRow span {
    margin: 0 12px;
}

.currentQuestionNumber {
    border-bottom: 4px solid white;
}

.headerTimerRight {
    font: normal normal bold 52px/63px MontserratBold;
}

.headerQuestionTitleLeft {
    font: normal normal bold 52px/87px MontserratBold;
    color: var(--lightBLue-color);
}

.smallHeaderQuestionTitleLeft {
    font: normal normal bold 30px/42px MontserratBold;
    color: var(--lightBLue-color);
}

.questionText {
    font: normal normal normal 41px/50px MontserratRegular;
    color: var(--white-color);
}

.smallQuestionText {
    font: normal normal normal 30px/40px MontserratRegular;
    color: var(--white-color);
}

.answerText {
    font: normal normal 400 28px/38px MontserratMedium;
    letter-spacing: 1.7px;
    color: var(--dark-fontcolor);
}

.upper {
    transform: translateY(-30px);
}

.resulstAnswerLetter {
    font: normal normal bold 30px/40px MontserratBold;
}

.resulstAnswerName {
    font: normal normal bold 18px/25px MontserratBold;
    letter-spacing: 1.55px;
    word-wrap: break-word;
    max-width: 200px;
}

.correctAnswerTextColor {
    color: var(--white-color);
}

.correctAnswerBg {
    background-color: var(--success-color);
}

.wrongAnswerLetterColor {
    color: var(--failur-textcolor);
}

.wrongAnswerNameColor {
    color: var(--dark-fontcolor);
}

.avatarPlaceHolder {
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50px;
    background-color: lightgray;
}

.thinAvatarPlaceHolder {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50px;
    background-color: lightgray;
}

.playerBigAvatarContaoner {
    z-index: 10 !important;
}

.avatarPlaceHolder img,
.thinAvatarPlaceHolder img,
.playerBigAvatarContaoner img {
    border-radius: 50%;
}

.playerRank {
    font: normal normal bold 55px/87px MontserratBold;
    letter-spacing: 2.75px;
    color: var(--lightBLue-color);
    border-radius: 9px;
    background-color: var(--white-color);
    height: 65px;
    min-width: 80px;
    line-height: 87px;
    position: relative;
}

.playerScoreAndRank {
    background-color: var(--white-color);
    height: 65px;
    min-width: 750px;
    border-radius: 9px;
    font: normal normal bold 31px/29px MontserratBold;
    letter-spacing: 1.55px;
    color: var(--dark-fontcolor);
    position: relative;
}

.playerRank::after {
    width: 80%;
    height: 8px;
    bottom: -8px;
}

.playerRank span {
    position: absolute;
    top: -16%;
    left: 30%;
}

.playerScoreAndRank::after {
    width: 95%;
    height: 8px;
    bottom: -8px;
}

.thinQualifiedHeader {
    font: normal normal 300 51px/93px MontserratThin;
    letter-spacing: 2.55px;
    color: var(--lightBLue-color);
}

.QualifiedTitle {
    font: normal normal bold 71px/93px MontserratBold;
    letter-spacing: 3.55px;
    color: var(--white-color);
}

.QualifiedPlayerName {
    font: normal normal bold 47px/87px MontserratBold;
    letter-spacing: 2.35px;
    color: var(--lightBLue-color);
}

.numberQualifiers {
    font: normal normal 300 41px/87px MontserratThin;
}

.g2PlayerTime {
    font: normal normal bold 52px/63px MontserratBold;
    letter-spacing: 0px;
    color: var(--white-color);
    opacity: 0.36;
}

.g2WinnerPlayerTime {
    opacity: 1;
}

.winnerTitleText {
    font: normal normal bold 71px/93px MontserratBold;
    letter-spacing: 3.55px;
    color: var(--white-color);
}

.tabTitle {
    font: normal normal bold 41px/87px MontserratBold;
    letter-spacing: 2.05px;
    color: #6EC0FF;
    opacity: 0.34;
}

.answerBtnsRow {
    margin-top: 50px;
    max-width: 700px;
}

.tabAnswerLetter {
    font: normal normal bold 120px/40px MontserratBold;
    letter-spacing: 7.1px;
    color: var(--dark-fontcolor);
}

.waitText {
    font: normal normal normal 41px/50px MontserratRegular;
    color: var(--white-color);
}

.playerResultCentering {
    place-content: center;
}

.zeroSecText {
    font: normal normal bold 104px/87px MontserratBold;
    letter-spacing: 5.2px;
    color: var(--lightBLue-color);
    margin-top: 150px;
}

.timesUpText {
    font: normal normal 300 50px/49px MontserratRegular;
    letter-spacing: 2.5px;
    color: var(--dark-fontcolor);
}

.timesUpTextContainer {
    background-color: var(--white-color);
    border-radius: 9px;
    position: relative;
    margin: 100px 130px 0;
    padding: 65px 0;
}

.timesUpTextContainer::after {
    width: 95%
}

.questionBanner {
    max-height: 160px;
    min-width: 300px !important;
    object-position: top !important;
}


.smallQuestionBanner {
    max-height: 80px;
    min-width: 150px !important;
    transform: translateY(-30px);
}

.bannersAnswers {
    min-height: 180px;
    object-position: top !important;
}

.loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid var(--lightBLue-disavledcolor);
    border-radius: 50%;
    border-top-color: var(--lightBLue-color);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    position: fixed;
    z-index: 1;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.loginPage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    background-color: #f8f9fd;
}

.loginPage input {
    font-family: MontserratRegular;
}

.loginPage label {
    font-family: MontserratBold;
}

input[type="text"],
input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: var(--lightBLue-color);
    border: none;
    box-shadow: 1px 1px 0 1px var(--lightBLue-disavledcolor);
    color: #fff;
    padding: 10px 20px;
}

input[type="submit"]:hover {
    background: #6cf0c2;
}

.button-container {
    display: flex;
    justify-content: center;
}

.login-form {
    background-color: white;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
    display: flex;
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    font-size: 25px;
    margin-bottom: 20px;
    font-family: MontserratBold;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
}

.errorContainer {
    width: fit-content;
}

.errorContainer {
    width: fit-content;
}

.cardClicked {
    transition: all .5s ease-in-out;
    animation: clickedEffect .5s ease-in-out;
    -webkit-animation: clickedEffect .5s ease-in-out;
}

@keyframes clickedEffect {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.125);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes clickedEffect {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.125);
    }

    100% {
        transform: scale(1);
    }
}